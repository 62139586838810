import React, { useEffect } from 'react';
import { toast, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Call it once in your app. At the root of your app is the best place
toast.configure()

export interface IToastProps extends ToastOptions {
  message: string;
}
export const Toast = (props: IToastProps) => {

  useEffect(() => {
    const { message, ...rest } = props;
    toast.info(props.message, {
      ...rest
    });
  }, [props]);

  return <div></div>
}