import { shopifyCartClient } from "../../domain/clients/shopify-cart/shopify-cart.client";
import { AddCartItem } from "../../domain/clients/shopify-cart/shopify-cart.interfaces";

/**
 * JS to execute on all pages.
 */
const Glide = require('@glidejs/glide').default as any;
export default (async () => {

  
  const glideAnnouncmentContainer = document.querySelector('.glide-announcement');
  const homeGlideAnnouncementBanner = new Glide(glideAnnouncmentContainer, {
    gap: 0,
    perView: 1,
    startAt: 0,
    focusAt: 'center',
    type: 'carousel'
  });

  if (glideAnnouncmentContainer) {
   homeGlideAnnouncementBanner.mount();
  }

  let mobileNavToggler = document.querySelector<HTMLSelectElement>('.mobilenav-toggler');
  let mobileNavMenu = document.querySelector<HTMLSelectElement>('.mobile-nav-container');
  let bodyTag = document.body;


  if (mobileNavToggler != null) {
    mobileNavToggler.addEventListener("click", toggleMenu);
  }



  function toggleMenu() {

    if (mobileNavToggler != null && mobileNavMenu != null) {
      mobileNavToggler.classList.toggle("nav-close");
      mobileNavMenu.classList.toggle("show-menu");
      bodyTag.classList.toggle("mobile-menu--opened");
    }
  }

  let childMobileMenus = document.querySelectorAll<HTMLSelectElement>('.has-child-nav');

  if (childMobileMenus != null) {

    for (let childMenu of childMobileMenus) {
      childMenu.addEventListener("click", event => {
        let showMenu = event.target as HTMLElement;
        childMenu.classList.toggle("show-nav");
      })

    }
  }



  let recoverPassword = document.querySelector<HTMLSelectElement>('#RecoverPassword');
  let loginForm = document.querySelector<HTMLSelectElement>('#CustomerLoginForm');
  let recoverPasswordForm = document.querySelector<HTMLSelectElement>('#RecoverPasswordForm');
  let hideRecoveryPasswordForm = document.querySelector<HTMLSelectElement>('#HideRecoverPasswordLink');

  if (recoverPassword != null) {
    recoverPassword.addEventListener("click", showPasswordForm);
  }

  if (hideRecoveryPasswordForm != null) {
    hideRecoveryPasswordForm.addEventListener("click", hidePasswordForm);
  }

  function showPasswordForm() {

    if (loginForm != null && recoverPasswordForm != null) {
      loginForm.classList.add('d-none');
      recoverPasswordForm.classList.remove('d-none');
    }
  }

  function hidePasswordForm() {

    if (loginForm != null && recoverPasswordForm != null) {
      loginForm.classList.remove('d-none');
      recoverPasswordForm.classList.add('d-none');
    }
  }



  //new address form

  let addNewAddressBtn = document.querySelector('#add-new-address');
  let newAddressForm = document.querySelector('#new-address-form');
  let cancelNewAddress = document.querySelector('#cancel-new-address');

  let editAddressForms = document.querySelectorAll('.edit-address-form');


  if (addNewAddressBtn) {
    addNewAddressBtn.addEventListener("click", toggleAddressForm);
  }

  if (cancelNewAddress) {
    cancelNewAddress.addEventListener("click", toggleAddressForm);
  }

  function toggleAddressForm() {
    if (newAddressForm) {
      newAddressForm.classList.toggle('d-none');

      closeAllEditAddressForms();

    }
  }

  let editAddressBtns = document.querySelectorAll('.edit-address');



  [...editAddressBtns].forEach((editAddressBtn) => {
    editAddressBtn.addEventListener("click", () => {

      closeAllEditAddressForms();

      let addressId = editAddressBtn.getAttribute('edit-form-id');
      let correspondingForm = document.querySelector('#edit-address-' + addressId);

      if (correspondingForm) {
        correspondingForm.classList.toggle('d-none');

        if (newAddressForm) {
          newAddressForm.classList.add('d-none');
        }
      }
    })
  })


  let cancelEditAddressBtns = document.querySelectorAll('.cancel-edit-address');

  [...cancelEditAddressBtns].forEach((cancelEditAddressBtn) => {
    cancelEditAddressBtn.addEventListener("click", () => {
      closeAllEditAddressForms();
    })
  })



  function closeAllEditAddressForms() {

    [...editAddressForms].forEach((editAddressForm) => {

      editAddressForm.classList.add('d-none');
    })
  }



  //

  let mealkitTemplate = document.querySelector('#mealkit-template');





  //order a meal kit

  let orderMealKitStep1 = document.querySelector('.order-mealkit-1');
  let orderMealKitStep2 = document.querySelector('.order-mealkit-2');
  let orderMealKitStep3 = document.querySelector('.order-mealkit-3');

  //number of meal kits


  const numOfMealKits = orderMealKitStep1?.getAttribute('mealkits-total');

  console.log("Number of meal Kits: " + numOfMealKits);

 let numberOfMealKits =  parseInt(numOfMealKits ?? 'NaN');


  //order a meal kit - meal kits


  let mealKit1Selected = false;
  let mealKit2Selected = false;
  const mealKitsSelected: { [mealKit:number]: boolean } = {};
  const mealKitQuantities: { [mealKit:number]: number } = {};
  const mealKitVariants: { [mealKit:number]: number } = {};
  const mealKitPrices: { [mealKit:number]: number } = {};
  const mealKitProductIds: { [mealKit:number]: number } = {};

  for (var m = 1; m <= numberOfMealKits; m++) {
    mealKitsSelected[m] = false;
    mealKitQuantities[m] = 1;

    let currentMealKit = 'mealkit-'+ m;

    const mealKitDefaultInput = document.querySelector<HTMLInputElement>(`input[type="radio"][name="${currentMealKit}"]:checked`);
    let mealKitDefaultPrice = parseInt(mealKitDefaultInput?.getAttribute('price') ?? 'NaN');
    let mealKitDefaultVariant = parseInt(mealKitDefaultInput?.getAttribute('value') ?? 'NaN');
    let mealKitProductId = parseInt(mealKitDefaultInput?.getAttribute('product-id') ?? 'NaN');

    mealKitPrices[m] = mealKitDefaultPrice;
    mealKitVariants[m] = mealKitDefaultVariant;
    mealKitProductIds[m] = mealKitProductId

  }

      // Create our number formatter.
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });









  const selectMealKitBtns = document.querySelectorAll('.select-meal-kit');

  [...selectMealKitBtns].forEach((selectMealKitBtn) => {
    selectMealKitBtn.addEventListener("click", () => {

      selectMealKitBtn.classList.toggle("selected-kit");

      if (selectMealKitBtn.classList.contains('selected-kit')) {

        selectMealKitBtn.innerHTML = 'Selected   &#10003';

        if (selectMealKitBtn.id==='mealkit-select-1') {
          mealKit1Selected = true;
          mealKitsSelected[1] = true;
        }

        else if (selectMealKitBtn.id==='mealkit-select-2') {
          mealKit2Selected = true;
          mealKitsSelected[2] = true;
        }
      }

      else {
        selectMealKitBtn.innerHTML = 'Select This Meal Kit';

        if (selectMealKitBtn.id='mealkit-select-1') {
          mealKit1Selected = false;
          mealKitsSelected[1] = false;
        }

        else if (selectMealKitBtn.id==='mealkit-select-2') {
          mealKit2Selected = false;
          mealKitsSelected[2] = false;
        }
      }

      console.log(mealKitsSelected);

      hasMealKitBeenSelected();

    })

  })


  let choosePickupBtn = document.querySelector<HTMLButtonElement>('.btn-choose-pickup');
  let reviewMealOrderBtn = document.querySelector<HTMLButtonElement>('.btn-review-kit');

  function hasMealKitBeenSelected() {
    let selectedMealKits = document.querySelectorAll('.selected-kit');

    console.log("Selected" + selectedMealKits);

    if (choosePickupBtn != null) {

      if (selectedMealKits) {
        choosePickupBtn.disabled = false;
      }

      else {
        choosePickupBtn.disabled = true;
      }
    }
  }


  //meal kit pricing


  function getSelectedMealKitVariantId(mealKitName: string) {
    const selectedMealKit = document.querySelector<HTMLInputElement>(`input[type="radio"][name="${mealKitName}"]:checked`);
    if (!selectedMealKit) return undefined;

    let variantIdStr = selectedMealKit.getAttribute('id');
    const variantId = parseInt(variantIdStr ?? 'NaN');
    return variantId
  }

  function selectMealKitVariant(mealKit: Element) {
    let variantId = mealKit.getAttribute('id');
    let variantPrice = parseInt(mealKit.getAttribute('price') ?? 'NaN');
    let mealKitNum = mealKit.getAttribute('name');
    let mealKitNumber = parseInt(mealKit.getAttribute('mk-num') ?? 'NaN'); 
    let mealKitProductId = parseInt(mealKit?.getAttribute('product-id') ?? 'NaN');

    console.log("Variant Price: " + variantPrice);
    console.log("Meal Kit Num" + mealKitNumber);
    mealKitPrices[mealKitNumber] = variantPrice;
    mealKitVariants[mealKitNumber] = parseInt(variantId ?? 'NaN');
    mealKitProductIds[mealKitNumber] = mealKitProductId;

    console.log(mealKitPrices);




    const variantOptionLabel = document.querySelector('[for=' + '"' + variantId + '"]');

    let mealKitLabels = document.querySelectorAll('label.' + mealKitNum);

    [...mealKitLabels].forEach((mealKitLabel) => {
      mealKitLabel.classList.remove('selected-input');
    })

    if (variantOptionLabel != null) {
      variantOptionLabel.classList.add('selected-input');
    }
    return variantId;
  }

  function addMealKitVariantListeners(mealKits: Element[]) {
    let mealKitSelectedVariant;

    mealKits.forEach((mealKit) => {
      mealKit.addEventListener("change", () => selectMealKitVariant(mealKit))
    })

    return mealKitSelectedVariant;

  }

  const mealKit1Options = [...document.querySelectorAll('[name="mealkit-1"]')];
  const mealKit2Options = [...document.querySelectorAll('[name="mealkit-2"]')];

  addMealKitVariantListeners(mealKit1Options);
  addMealKitVariantListeners(mealKit2Options);

  let mealKitSelectedVariant1 = getSelectedMealKitVariantId('mealkit-1');
  let mealKitSelectedVariant2 = getSelectedMealKitVariantId("mealkit-2");

  console.log(mealKitSelectedVariant1);
  console.log("Selected Meal Kit 2:" + mealKitSelectedVariant2);


  //meal kit quantity

  const mealKitQuantyEles = document.querySelectorAll('input[name="quantity"]');

  [...mealKitQuantyEles].forEach((mealKitQuantity) => {
    mealKitQuantity.addEventListener("input", (e:Event) => {
      const inputEle = (e.target as HTMLInputElement);
      const mealKitQuantityNum = parseInt(inputEle.getAttribute('data-mealkit') ?? 'NaN');
      if(!mealKitQuantityNum) return;
      mealKitQuantities[mealKitQuantityNum] = parseInt(inputEle.value ?? 'NaN');
      console.log(mealKitQuantities);
    })
  })



  //order a meal kit - locations


  const pickupLocations = document.querySelectorAll('[name="pickup-locations"]');

  const selectedLocation = document.querySelector('#selected-location');

  const reviewSelectedLocation = document.querySelector('#review-pickup-location');

  let customerSelectedLocation:string | null;

  let locationHasBeenSelected = false;


  [...pickupLocations].forEach((pickupLocation) => {
    pickupLocation.addEventListener("change", () => {


      let locationLabels = document.querySelectorAll('.location-label');

      [...locationLabels].forEach((locationLabel) => {

        locationLabel.classList.remove('selected-input');

      })

      let pickupLocationKey = pickupLocation.getAttribute('location-id');


      customerSelectedLocation = pickupLocationKey;


      const pickupLocationLabel = document.querySelector('[for=' + pickupLocationKey + ']');

      const pickupLocationText = document.querySelector('[location-text=' + pickupLocationKey + ']');



      if (pickupLocationLabel != null) {
        pickupLocationLabel.classList.add('selected-input');
        locationHasBeenSelected = true;
        if (reviewMealOrderBtn) {
          reviewMealOrderBtn.disabled = false;
        }

      }

      if (selectedLocation != null && pickupLocationText != null) {
        selectedLocation.innerHTML = pickupLocationText.innerHTML;
      }

      if (reviewSelectedLocation != null && pickupLocationText != null) {
        reviewSelectedLocation.innerHTML = pickupLocationText.innerHTML;
      }



      console.log("Location selected is: " + customerSelectedLocation);


    })
  })




  //review

  let mealKitTotalCost = 0;
  let reviewMealTotalCost = document.querySelector('#meal-total-cost');

  function getReview() {

    console.log("Meal Kit 1 Selected: " + mealKit1Selected + "Meal Kit 1 Variant Selected: " + mealKitSelectedVariant1);
    console.log("Meal Kit 2 Selected: " + mealKit2Selected + "Meal Kit 2 Variant Selected: " + mealKitSelectedVariant2);

    let reviewMealKitsTotalCost = document.querySelector('#mealkit-total-cost');



    for (var i = 1; i <= numberOfMealKits; i++) {

      if  (mealKitsSelected[i] == true) {
        console.log(mealKitsSelected[i] + " selected");
      let reviewMealKitRow = document.querySelector('#mealkit-row-'+ i);
      let reviewMealKitQty = document.querySelector('#mealkit-review-qty-'+ i); 
      let reviewMealKitPrice = document.querySelector('#mealkit-review-price-'+ i); 
      let reviewMealKitTotal = document.querySelector('#mealkit-review-total-'+ i); 

      if (!(reviewMealKitQty && reviewMealKitPrice && reviewMealKitTotal)) {
        console.log('missing');
        return;
      }
        
      let formattedPrice = formatter.format(mealKitPrices[i] / 100); 

      reviewMealKitRow?.classList.remove('d-none'); 

      let mealKitTotal = mealKitQuantities[i] * mealKitPrices[i];

      mealKitTotalCost = mealKitTotalCost + mealKitTotal;

      let formattedTotal = formatter.format(mealKitTotal / 100); 

      reviewMealKitQty.innerHTML =  String(mealKitQuantities[i]);
      reviewMealKitPrice.innerHTML =  String(formattedPrice);
      reviewMealKitTotal.innerHTML = String(formattedTotal);

    }
  }

  let formattedTotalMealKitCost = formatter.format(mealKitTotalCost / 100);

  if (reviewMealKitsTotalCost != null && reviewMealTotalCost != null) {
    reviewMealKitsTotalCost.innerHTML = String(formattedTotalMealKitCost);
    reviewMealTotalCost.innerHTML = String(formattedTotalMealKitCost);
  }
}

  getReview();



  //rove it forward

  const roveBtn = document.querySelector('#rove-btn');
  let addRoveToOrder = false;

  
  let reviewRoveCost = document.querySelector('#rove-total-cost');

  const roveQuantity = document.querySelector('#rove-qty');
  const roveId = roveQuantity?.getAttribute('rove-id');
  let rovePrice = 200;

  if (roveQuantity) {

    rovePrice = parseInt(roveQuantity.getAttribute('price') ?? 'NaN');
  }


  let roveInputEleQuantity = 1;
  
  let roveTotal = rovePrice * roveInputEleQuantity; 


  roveBtn?.addEventListener("click", ()  => {

    let originalTotalCost = mealKitTotalCost;

    if (roveBtn.classList.contains('selected-kit')) {
      roveBtn.classList.remove('selected-kit');
      roveBtn.innerHTML = 'Add to my meal';
      addRoveToOrder = false;
      if (reviewRoveCost != null  && reviewMealTotalCost != null) {
        console.log(reviewRoveCost);
        reviewRoveCost.innerHTML = String(formatter.format(0 / 100));
        reviewMealTotalCost.innerHTML = String(formatter.format(originalTotalCost / 100));
      }
    } 

    else {
      roveBtn.classList.add('selected-kit');
      roveBtn.innerHTML = 'Added &#10003';
      addRoveToOrder = true;

      if (reviewRoveCost != null && reviewMealTotalCost != null) {
        console.log(reviewRoveCost);
        reviewRoveCost.innerHTML = String(formatter.format(roveTotal / 100));
        let newTotal = roveTotal + mealKitTotalCost;
        reviewMealTotalCost.innerHTML = String(formatter.format(newTotal / 100));
      }
    }
  })


  
    if (roveQuantity != null) {
      roveQuantity.addEventListener("input", (e:Event) => {
          const roveInputEle = (e.target as HTMLInputElement);
          roveInputEleQuantity = parseInt(roveInputEle.value ?? 'NaN');
          roveInputEleQuantity = isNaN(roveInputEleQuantity) ? 0 : Math.max(roveInputEleQuantity, 0);

          let rovePrice = parseInt(roveInputEle.getAttribute('price') ?? 'NaN');
          roveTotal = roveInputEleQuantity * rovePrice;

          if (addRoveToOrder == true && reviewRoveCost && reviewMealTotalCost) {
            reviewRoveCost.innerHTML = String(formatter.format(roveTotal / 100));
            let costIncludingRove = roveTotal + mealKitTotalCost;
            reviewMealTotalCost.innerHTML = String(formatter.format(costIncludingRove / 100));
          }
        })
    }




    //add to cart

    const addToCart = async (navigateToURLAfter:string) => {



      const addToCartItems: AddCartItem[] = [];

      if (addRoveToOrder && roveId && roveInputEleQuantity) {
        console.log(roveId + ' ' + roveInputEleQuantity);
        addToCartItems.push({
          id: parseInt(roveId),
          quantity: roveInputEleQuantity,
        })
      }

      const locationData = (window as any)[`location_${customerSelectedLocation}`] as {
        address:string;
        pickup_date:string;
        pickup_date_formatted: string;
        key:string;
        name:string;
        time:string;
        week_number:string;
      }
      const {
        address, pickup_date, pickup_date_formatted, key, name, time, week_number
      } = locationData;

      const makeProperties = ():{[key:string]: string} => ({
        'Pickup Location': `${name}, ${pickup_date_formatted}, ${time}, ${address}`,
        _address:address, 
        _pickup_date: pickup_date, 
        _key:key, 
        _name:name, 
        _time:time,
        _week: week_number
      })

      
      for (var i = 1; i <= numberOfMealKits; i++) {
        if  (mealKitsSelected[i] == true) {
          
          let currentProperties = makeProperties(); 
          const mealKitProductId = mealKitProductIds[i];
          let productData = (window as any)[`product_${mealKitProductId}`] as any;
          if( productData && productData.type ){
            currentProperties['Meal Kit'] = productData.type;
          }

          addToCartItems.push({
            id: mealKitVariants[i],
            quantity: mealKitQuantities[i],
            properties: currentProperties
          })
          console.log(
            mealKitVariants[i] + ' ' + mealKitQuantities[i]);
        } 
      }


      try {
        const response = await shopifyCartClient.addItems( addToCartItems  )
        window.location.href = navigateToURLAfter
      } catch (e){
        console.error(e);
      }

    }


    const checkoutNow = document.querySelector('.btn-checkout-now');
    const checkoutLater = document.querySelector('.btn-checkout-later');

    checkoutNow?.addEventListener("click", () => addToCart('/checkout') )
    checkoutLater?.addEventListener("click", () => addToCart('/cart') )



    //change pickup location 

    const changePickupLocationBtn = document.querySelector('.btn-change-pickup');
    if (changePickupLocationBtn) {
      changePickupLocationBtn.addEventListener("click", () => {

        if (orderMealKitStep1 && orderMealKitStep2 && orderMealKitStep3) {
        changeStep(orderMealKitStep1, orderMealKitStep2);

        orderMealKitStep3.classList.add('d-none');
        }

        if (step2Icon && step1Icon && step3Icon) {
          activeStepIcon(step2Icon);
          visitedStepIcon(step1Icon);
          visitedStepIcon(step3Icon);
        }

      })
    }




  let step1Icon = document.querySelector('#step1-icon');
  let step2Icon = document.querySelector('#step2-icon');
  let step3Icon = document.querySelector('#step3-icon');


  //step 1 to step 2

  choosePickupBtn?.addEventListener("click", () => {
    if (orderMealKitStep1 && orderMealKitStep2) {
      changeStep(orderMealKitStep1, orderMealKitStep2);
    }

    if (step2Icon && step1Icon) {
      activeStepIcon(step2Icon);
      visitedStepIcon(step1Icon);
    }

    mealKitSelectedVariant1 = getSelectedMealKitVariantId('mealkit-1');
    mealKitSelectedVariant2 = getSelectedMealKitVariantId("mealkit-2");

    getReview();
  })


  //step 2 to 3

  reviewMealOrderBtn?.addEventListener("click", () => {
    if (orderMealKitStep2 && orderMealKitStep3) {
      changeStep(orderMealKitStep2, orderMealKitStep3);
    }

    if (step2Icon && step1Icon && step3Icon) {
      activeStepIcon(step3Icon);
      visitedStepIcon(step2Icon);
      visitedStepIcon(step1Icon);
    }

  })


  //changing steps

  function changeStep(hideStep: Element, showStep: Element) {


    if (hideStep && showStep) {
      hideStep.classList.add('d-none');
      showStep.classList.remove('d-none');

      if (mealkitTemplate != null) {
      mealkitTemplate.scrollIntoView({behavior: "smooth"});
      }

    }
  }

  //active icon

  function activeStepIcon(activeIcon: Element) {
    if (activeIcon) {
      activeIcon.classList.add('active-step');
      activeIcon.classList.remove('visited-step');
      activeIcon.classList.remove('inactive-step');
    }
  }

  function inactiveStepIcon(inactiveIcon: Element) {
    if (inactiveIcon) {
      inactiveIcon.classList.remove('active-step');
      inactiveIcon.classList.remove('visited-step');
      inactiveIcon.classList.add('inactive-step');
    }
  }

  function visitedStepIcon(visitedIcon: Element) {
    if (visitedIcon) {
      visitedIcon.classList.remove('active-step');
      visitedIcon.classList.add('visited-step');
      visitedIcon.classList.remove('inactive-step');
    }
  }




  const infoLinks = document.querySelectorAll('.info-title');

  if (infoLinks) {

  [...infoLinks].forEach((infoLink) => { 
    infoLink.addEventListener("click", () => { 

      infoLink.classList.toggle('show-block');
      })
  })
}


})();
