import {
  AddCartItem,
  AddToCartError,
  Cart,
  ChangeCartItem,
  UpdateCartItems,
} from "./shopify-cart.interfaces";

export class ShopifyCartClient {
  private cart?: Cart;

  clearCart = async () => {
    const resp = await fetch("/cart/clear");
    if (!resp.ok) {
      throw new Error("Unable to clear cart.");
    }
    return true;
  };

  getCart = async (cacheBust = false) => {
    if(this.cart && ! cacheBust){
      return this.cart
    }

    const resp = await fetch("/cart.js", {
      method: 'GET',
    });
    const respJSON = await resp.json();
    if (!(resp.status === 200 || resp.status === 201)) {
      throw new Error(
        (respJSON as AddToCartError).message ?? "Unable to get cart."
      );
    }
    this.cart = respJSON as Cart;
    return this.cart;
  };

  addItems = async (items: AddCartItem[], attributes?:{[key:string]: string}, note?:string) => {
    let bodyObj: {[key:string]: any} = { items };
    if(attributes){
      bodyObj.attributes = attributes
    }
    if(note){
      bodyObj.note = note;
    }
    const body = JSON.stringify(bodyObj);

    const resp = await fetch("/cart/add.js", {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body,
    });
    const respJSON = await resp.json();
    if (!(resp.status === 200 || resp.status === 201)) {
      throw new Error(
        (respJSON as AddToCartError).message ?? "Unable to add items to cart."
      );
    }
    return respJSON;
  };

  updateItems = async (payload: UpdateCartItems) => {
    const body = JSON.stringify(payload);

    const resp = await fetch("/cart/update.js", {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body,
    });
    const respJSON = await resp.json();
    if (!(resp.status === 200 || resp.status === 201)) {
      throw new Error(
        (respJSON as AddToCartError).message ??
          "Unable to update items in cart."
      );
    }
    return respJSON;
  };

  changeItem = async (payload: ChangeCartItem) => {
    const body = JSON.stringify(payload);

    const resp = await fetch("/cart/change.js", {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body,
    });
    const respJSON = await resp.json();
    if (!(resp.status === 200 || resp.status === 201)) {
      throw new Error(
        (respJSON as AddToCartError).message ?? "Unable to update cart item."
      );
    }
    return respJSON;
  };

  refreshCartCount = async () => {
    const cart = await this.getCart(true);
    [...document.querySelectorAll<HTMLElement>('.cart-count')].forEach(ele => {
      ele.innerText = `${cart.item_count}`;
    })
  }
}

export const shopifyCartClient = new ShopifyCartClient();