import { ShopifyPages } from '../domain/shopify-pages.enum';
import 'lazysizes';
import './controllers/_development';
import './controllers/all';

export default (async () => {

  document.addEventListener("DOMContentLoaded", async (event) => { 
    if ( window.location.href.toLowerCase().indexOf(ShopifyPages.Cart) >= 0 ){
      return await import(/* webpackChunkName: "cart" */ './controllers/cart');
    } else if ( window.location.href.toLowerCase().indexOf(ShopifyPages.Product) >= 0 ){
      return await import(/* webpackChunkName: "product" */ './controllers/product');
    } else  if ( window.location.href.toLowerCase().indexOf(ShopifyPages.Collections) >= 0 ){
      return await import(/* webpackChunkName: "collection" */ './controllers/collection');
    } else if ( window.location.href.toLowerCase().indexOf(ShopifyPages.Search) >= 0 ){
      return await import(/* webpackChunkName: "search" */ './controllers/search');
    } else if ( window.location.href.toLowerCase().indexOf(ShopifyPages.Login) >= 0 ){
      return await import(/* webpackChunkName: "login" */ './controllers/login');
    } else if ( window.location.pathname === '/' ){
      return await import(/* webpackChunkName: "home" */ './controllers/home');
    } 
    else if ( window.location.href.toLowerCase().indexOf(ShopifyPages.FAQ) >= 0 ){
      return await import(/* webpackChunkName: "faq" */ './controllers/faq');
    }
  });
})();

require('../../layout/theme.liquid')
