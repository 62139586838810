import React from 'react'
import ReactDOM from 'react-dom';
import io from 'socket.io-client';
import { Toast } from '../../components/atoms/Toast';

export default (async () => {


  let hasOpenedNotifier = false;
  let hasOpenedBuildNotifier = false;
  var scrollPos = parseFloat(localStorage.getItem('scroll-y') || '0') || 0;
  var prevPage = localStorage.getItem('prev-page') || '';


  var disconnected = false
  var socket = io('https://localhost:4001', {
    reconnectionAttempts: 1
  })
  socket.on('connect', function () {
    console.group("better shopify - connected");
    if (scrollPos && prevPage === window.location.pathname) {
      window.scrollTo(0, scrollPos);
    }
  });
  socket.on('event', function (data: any) {
    console.log('event.', data)
  });
  socket.on('disconnect', function () {
    console.log('disconnected.')
    disconnected = true;
  });
  socket.on('refresh', function () {
    console.log("refreshing...");

    setTimeout(() => {
      window.location.reload();
    }, 100)

    if (!hasOpenedNotifier) {
      const newEle = document.createElement('div');
      document.body.appendChild(newEle);
      ReactDOM.render(
        <Toast
          autoClose={500}
          message="Reloading browser..."
          position="top-right"
        />,
        newEle)
      hasOpenedNotifier = true;
    }

  });

  window.onunload = () => {
    localStorage.setItem('scroll-y', String(window.scrollY));
    localStorage.setItem('prev-page', window.location.pathname);
  }

  socket.on('build', function () {
    console.log("starting build... :) ");


    if (!hasOpenedBuildNotifier) {
      const newEle = document.createElement('div');
      document.body.appendChild(newEle);
      ReactDOM.render(
        <Toast
          autoClose={false}
          message="Starting build..."
          position="top-right"
        />,
        newEle)
      hasOpenedBuildNotifier = true;
    }

  });
  socket.on('reconnect_failed', function reconnectFailed(e: any) {
    if (disconnected) return
    console.error('better shopify -- connection failed.')
  })


})();